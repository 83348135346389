import React from 'react'
import styled from 'styled-components'
import { HeroImageTitle } from 'components/new/hero'
import { Helmet } from 'react-helmet'
import Layout from 'components/layout'
import { graphql } from 'gatsby'
import Content from 'components/new/content'

const SpringCatalogPage = props => {
  const {
    data: { heroImage },
  } = props
  return (
    <Layout>
      <Helmet>
        <title>2025 Spring Catalog | Hutson Inc</title>
      </Helmet>

      <Hero
        image={heroImage.childImageSharp.gatsbyImageData}
        title='2025 Spring Catalog'
        overlayOpacity={0.3}
      />

      <Content kind='full'>
        <div style={{ height: '70vh', minHeight: '250px' }}>
          <iframe
            src='https://player.flipsnack.com?hash=NTk3NUFCREQ3NUUrYzNxMTBlMDk1ZQ=='
            title='Ag Service Maintenance Agreements'
            width='100%'
            height='100%'
            seamless='seamless'
            scrolling='no'
            frameBorder='0'
            allowFullScreen
            allow='autoplay; clipboard-read; clipboard-write'
          ></iframe>
        </div>
      </Content>
    </Layout>
  )
}

const Hero = styled(HeroImageTitle)`
  height: 300px;

  @media (min-width: 900px) {
    height: 400px;
  }
`

export const pageQuery = graphql`
  {
    heroImage: file(relativePath: { eq: "landing-pages/spring-catalog-scoop.jpg" }) {
      ...FullWidthImage
    }
  }
`

export default SpringCatalogPage
